"use client";

import { useEffect } from "react";
import Link from "next/link";
import * as Sentry from "@sentry/nextjs";
import { ServerCrashIcon } from "lucide-react";

import { Button, buttonVariants } from "@/components/ui/button";

const HOME_PATH = "/";

export default function Error({
  error,
  reset,
}: {
  error: Error & { digest?: string };
  reset: () => void;
}) {
  useEffect(() => {
    // Log the error to Sentry
    Sentry.captureException(error);
  }, [error]);

  return (
    <>
      <main className="flex min-h-screen items-center justify-center">
        <div className="flex max-w-[340px] flex-col">
          <div className="mb-5">
            <ServerCrashIcon
              strokeWidth={1}
              className="mx-auto h-[50px] w-[50px]"
            />
          </div>
          <div className="flex flex-row items-center justify-center	space-x-3">
            <div className="text-xl">500</div>
            <div className="text-muted-foreground">|</div>
            <div className="text-lg">Internal Server Error</div>
          </div>
          <div className="py-3 text-center text-sm leading-6 text-muted-foreground">
            Oops, something went wrong. <br /> Please try again later.
          </div>
          <div className="flex flex-row justify-center space-x-5 py-3">
            <Link
              className={buttonVariants({ variant: "outline" })}
              href={HOME_PATH}
            >
              Back to Home
            </Link>
            <Button variant={"outline"} onClick={() => reset()}>
              Reset
            </Button>
          </div>
        </div>
      </main>
    </>
  );
}
